import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppInfoService } from '../../../@uno-app/service/app.info.service';
import { Profiler } from '../../api/common.service';
import { Common, DesignerConstants, EM, UnoCompEvents, UnoComponentManager } from '../../core';

export class UnoCoreBaseComp extends React.Component<any, any> {
    inputID = Common.getUniqueKey(`${this.constructor.name}_`);
    protected profiler = Profiler.init(`${this.inputID}`);

    protected unmounted = false;
    start_time = new Date().getTime();
    stateTrail: Array<any> = [];

    private _event_handlers: any = [];
    private _set_focus_handler: any;

    private redirectTo: any;

    constructor(props: any) {
        super(props);
        this.profiler.disabled = !this.canProfile();
        this.state = {
            ...props,
            _uuid_: this.inputID,
        };
        this.profiler.log('Constructed', this.constructor.name, this.state);

        this._set_focus_handler = EM.register(Common.Event.SET_FOCUS, async (compID: any) => {
            if (compID === this.getInputID()) {
                const ele = window.document.getElementById(compID);
                if (ele) {
                    ele.focus();
                }
            }
        });

    }

    componentDidMount() {
        this.profiler.log('Mounted', { ...this.state });
        this._event_handlers = [...this._event_handlers, ...Common.registerEventHandlers(this)];
        Common.notifyEvent(this, UnoCompEvents.onLoad);
    }

    componentWillUnmount() {
        Common.notifyEvent(this, UnoCompEvents.onUnLoad);
        Common.unregisterEventHandlers(this._event_handlers, this);
        EM.unregister(this._set_focus_handler,);
        this.unmounted = true;
        this.profiler.log('Un-Mounted', { ...this.state });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        this.profiler.log('Rendering Updated', { ...this.state });
        Common.notifyEvent(this, UnoCompEvents.onViewUpdate);
    }

    render(): JSX.Element | undefined | null {
        this.profiler.log('Being Rendered', !this.unmounted, { ...this.state });
        if (this.unmounted) {
            return undefined;
        }

        this.stateTrail.push({ ...this.state });

        return (
            <>
                {this.buildRedirectTo()}
                {this.buildComp()}
            </>
        );
    }

    buildComp(): any {
        return undefined;
    }

    isLiveMode = () => {
        return DesignerConstants.isLiveMode(this);
    }

    getDisplayMode() {
        return this.state?.displayMode || this.props?.displayMode;
    }

    buildRedirectTo() {
        let redirectTo = this.redirectTo;
        if (redirectTo) {
            this.profiler.log(this.constructor.name, 'Redirect to:', redirectTo);
            const redirectToCmp = (<Redirect to={redirectTo} push={true} />);
            this.redirectTo = undefined;
            return redirectToCmp;
        }
        return undefined;
    }

    reRender = (state: Object = {}, clbk?: () => void) => {
        if (!this.unmounted) {
            this.profiler.log(this.constructor.name, 'Re-render-', { ...state }, { ...this.state });
            this.setState(state, clbk);
        }
    }

    setRedirectTo = (to?: string) => {
        this.redirectTo = to;
        /*
        if (to) {
            this.profiler.log('Window Redirect to: ', to);
            window.location.assign(to);
        }
        */
        this.reRender();
    }

    getStyles() {
        const styles = Common.safeParse(this.state.styles);
        return styles;
    }

    getStyleClasses() {
        const styles = this.state.styleClasses; // Common.safeParse(this.state.styleClasses);
        return styles ? styles : '';
    }

    getAppID() {
        let appID = this.state.app_id || this.state.appID || AppInfoService.getActiveApp()?.id;
        return appID;
    }

    getInputID = () => {
        return this.state.input_id || this.inputID;
    }

    getCompConfig() {
        return this.props.compConfig;
    }

    getCompLabel() {
        const config = this.getCompConfig();
        if (config) {
            return UnoComponentManager.getCompLabel(config.compID);
        } else {
            return undefined;
        }
    }

    canProfile() {
        let enabled = false;
        return enabled;
    }
}
